



























































































@import "@/assets/css/project-variables";
.chart-type-radio-group {
  display: flex;

  .chart-type-radio{
    .el-radio__inner {
      display: none;
    }

    img{
      height: 100px;
    }

    div{
      margin-top: 10px;
      font-size: 16px;
      text-align: center;
    }
  }
  .chart-type-radio.is-checked {
    border: 4px solid $--color-primary;
    border-radius: $--border-radius-base;
  }
}




